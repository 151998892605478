import { combineReducers } from "redux";
import user from "./userReducer";
import client from "./clientReducer";
import property from "./propertyReducer";
import global from "./globalReducer";
import jobs from "./jobReducer";
import jobsFilter from "./jobsFilterReducer";
import programTemplate from "./programTemplateReducer";

const rootReducer = combineReducers({
  user: user,
  client: client,
  property: property,
  global: global,
  jobs: jobs,
  jobsFilter,
  programTemplate,
});

export default rootReducer;
