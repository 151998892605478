import { SET_PROPERTY_NAME_LIST, SET_PROPERTY_ADDRESS_LIST } from '@/redux/actions/propertyActions'

const initialUserState = {
    names: [],
    address: [],
};

const clientReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case SET_PROPERTY_NAME_LIST:
            return { ...state, names: action.payload }
        case SET_PROPERTY_ADDRESS_LIST:
            return { ...state, address: action.payload }
        default:
            return { ...state }
    }
};

export default clientReducer;
