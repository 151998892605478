import getConfig from 'next/config';
import { userService } from '@/services/user-service';

const { publicRuntimeConfig } = getConfig();

export const fetchWrapper = { get, post, put, sendData, delete: _delete, patch, postCors };

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);

        if (!response.ok) {
            if ([401, 403, 409].includes(response.status) && userService.userValue) {
                userService.logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

function authHeader(url) {
    let user = userService.userValue;
    if (user && user.hasOwnProperty('user')) {
        user = user.user;
    }

    console.log('User', user);
    console.log('Url', url);
    const isLoggedIn = user && user.token;
    const isApiUrl = url.includes(`/api/`);
    if (isLoggedIn && isApiUrl) {
        return { Authorization: `Bearer ${user.token}` };
    } else {
        return {};
    }
}

function get(url, headers = {}) {
    const requestOptions = {
        keepalive: true,
        method: 'GET',
        headers: { 'Content-Type': 'application/json', ...authHeader(url), ...encodeHeaders(headers) },
        credentials: 'include'
    };

    
    return fetch(url, requestOptions).then(handleResponse);
}

function encodeHeaders(headers) {
    if (headers.text) {
        return { ...headers, text: encodeURIComponent(headers.text) };
    }

    return headers;
}

function post(url, body, headers = {}) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', ...authHeader(url), ...encodeHeaders(headers) },
        credentials: 'include',
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body, headers = {}) {
    const requestOptions = {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', ...authHeader(url), ...encodeHeaders(headers) },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function _delete(url, body, headers = {}) {
    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', ...authHeader(url), ...encodeHeaders(headers) },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function patch(url, body, headers = {}) {
    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json', ...authHeader(url), ...encodeHeaders(headers) },
        body: JSON.stringify(body)
    };

    return fetch(url, requestOptions).then(handleResponse);
}

// added multi-form data
function sendData(url, data) {
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
        formData.append(key, value);
    }

    const requestOptions = {
        method: 'POST', 
        headers: authHeader(url),
        body: formData
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function postCors(url, body) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', ...authHeader(url) },
        body: JSON.stringify(body)
    };
    return fetch(url, requestOptions).then(handleResponse);
}