/**
 * @readonly
 * @enum {string}
 */
const SortBy = {
  Name: "clientName",
  Date: "dateAdded",
};

export default SortBy;
