export const SET_PROPERTY_NAME_LIST = 'SET_PROPERTY_NAME_LIST';
export const SET_PROPERTY_ADDRESS_LIST = 'SET_PROPERTY_ADDRESS_LIST';

export const setPropertyNames = (propertyNamesList) => ({
    type: SET_PROPERTY_NAME_LIST,
    payload: propertyNamesList
});

export const setPropertyAddress = (propertyAddressList) => ({
    type: SET_PROPERTY_ADDRESS_LIST,
    payload: propertyAddressList
});
