/**
 * @readonly
 * @enum {string}
 */
const SortOrder = {
  Ascending: "ASC",
  Descending: "DESC",
};

export default SortOrder;
