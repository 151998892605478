export const INITIALIZE_JOB_FILTERS = "INITIALIZE_JOB_FILTERS";
export const SET_JOB_SEARCH = "SET_JOB_SEARCH";
export const SET_JOB_FILTERS = "SET_JOB_FILTERS";
export const SET_JOB_SORT = "SET_JOB_SORT";
export const RESET_JOB_FILTERS = "REINITIALIZE_JOB_FILTERS";

export function initializeJobFilters() {
  return { type: INITIALIZE_JOB_FILTERS };
}

export function setJobSearch(search) {
  return { type: SET_JOB_SEARCH, payload: { search } };
}

export function setJobFilters(filters) {
  return { type: SET_JOB_FILTERS, payload: { filters } };
}

export function setJobSort(sort) {
  return { type: SET_JOB_SORT, payload: sort };
}

export function resetJobFilters() {
  return { type: RESET_JOB_FILTERS };
}
