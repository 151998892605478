import Head from "next/head";
import { Toaster } from "react-hot-toast";
import { wrapper } from "@/redux/store";
import RouteGuard from "@/components/RouteGuard";
import Script from "next/script";
import { useRef, useEffect } from "react";
import { useRouter } from "next/router";
import { resetActiveSoil } from "@/redux/actions/jobActions";
import { useDispatch } from "react-redux";
import { APIProvider } from "@vis.gl/react-google-maps";

import "tailwindcss/tailwind.css";
import "react-phone-number-input/style.css";
import "@/styles/globals.css";
import "@/styles/login.css";
import "@/styles/layout.css";
import "@/styles/common-forms.css";
import "@/styles/settings.css";
import "@/styles/table.css";
import "@/styles/avatar.css";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "react-multi-carousel/lib/styles.css";

import dotenv from "dotenv";
dotenv.config(); // Load environment variables from .env file


const HybridAGApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const previousPath = useRef();

  useEffect(() => {
    console.log("NODE_ENV:", process.env.NODE_ENV);
    if (router.isReady) {
      if (
        previousPath.current === "/jobs/blend/[uuid]" &&
        router.pathname !== "/jobs/[uuid]"
      ) {
        dispatch(resetActiveSoil());
      }

      previousPath.current = router.pathname;
    }
  }, [router.pathname, router.isReady]);

  return (
    <>
      <Head>
        <title>{`AI-Ag${
          process.env.NODE_ENV == "development" ? " 🚧" : ""
        }`}</title>
        <link rel="icon" href="/images/favicon.ico" type="image/x-icon" />
        <link
          rel="shortcut icon"
          href="/images/favicon.ico"
          type="image/x-icon"
        />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `window.dataLayer = window.dataLayer || [];
                                function gtag(){dataLayer.push(arguments);}
                                gtag('js', new Date());
                                gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
                                    page_path: window.location.pathname,
                                });
                            `,
        }}
      />
      <RouteGuard>
        <Toaster position="top-right" reverseOrder={false} />
        <APIProvider apiKey={process.env.NEXT_PUBLIC_GOOGLE_CLOUD_API_KEY}>
          <Component {...pageProps} />
        </APIProvider>
      </RouteGuard>
    </>
  );
};

export default wrapper.withRedux(HybridAGApp);
