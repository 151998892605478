export const RESET_CLIENT_NAV_OPTION = 'RESET_CLIENT_NAV_OPTION';
export const SET_CLIENT = 'SET_CLIENT';
export const SET_CLIENT_LIST = 'SET_CLIENT_LIST';
export const SET_ADD_UPDATE_CLIENT = 'SET_ADD_UPDATE_CLIENT';
export const SET_CLIENT_JOBS_LIST = 'SET_CLIENT_JOBS_LIST';
export const SET_FILTERED_JOBS_LIST = 'SET_FILTERED_JOBS_LIST';
export const SET_CLIENT_CURRENT_NAV_ITEM = 'SET_CLIENT_CURRENT_NAV_ITEM';
export const SET_CLIENT_PROPERTY_LIST = 'SET_CLIENT_PROPERTY_LIST';
export const SET_CLIENT_PROPERTY = 'SET_CLIENT_PROPERTY';

export const resetClientNavOption = () => ({
    type: RESET_CLIENT_NAV_OPTION
});

export const setClient = (client) => ({
    type: SET_CLIENT,
    payload: client
});

export const setClientList = (clientList) => ({
    type: SET_CLIENT_LIST,
    payload: clientList
});

export const setAddUpdateClient = (client) => ({
    type: SET_ADD_UPDATE_CLIENT,
    payload: client
});

export const setClientJobsList = (jobList) => ({
    type: SET_CLIENT_JOBS_LIST,
    payload: jobList
});

export const setFilteredJobsList = (jobList) => ({
    type: SET_FILTERED_JOBS_LIST,
    payload: jobList
});

export const setClientCurrentNavItem = (item) => ({
    type: SET_CLIENT_CURRENT_NAV_ITEM,
    payload: item
});

export const setClientPropertyList = (list) => ({
    type: SET_CLIENT_PROPERTY_LIST,
    payload: list
});

export const setClientProperty = (item) => ({
    type: SET_CLIENT_PROPERTY,
    payload: item
});