export const RESET_NAV_ITEM = "RESET_NAV_ITEM";
export const SET_JOBS_LIST = "SET_JOBS_LIST";
export const SET_FILTERED_JOBS_LIST = "SET_FILTERED_JOBS_LIST";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const SET_LOADING = "SET_LOADING";
export const SET_CURRENT_JOB = "SET_CURRENT_JOB";
export const SET_CURRENT_NAV_ITEM = "SET_CURRENT_NAV_ITEM";
export const UPDATE_JOB_LIST = "UPDATE_JOB_LIST";
export const UPDATE_JOB_STAGE = "UPDATE_JOB_STAGE";
export const SET_NUTRIENTS = "SET_NUTRIENTS";
export const SET_ACTIVE_NAV_TEST = "SET_ACTIVE_NAV_TEST";
export const SET_ACTIVE_TEST_DATA = "SET_ACTIVE_TEST_DATA";
export const ADD_PROGRAM_DATA = "ADD_PROGRAM_DATA";
export const SET_ACTIVE_PROGRAM = "SET_ACTIVE_PROGRAM";
export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const UPDATE_SOIL_GROUP = "UPDATE_SOIL_GROUP";
export const UPDATE_LEAF_DATA_LIST = "UPDATE_LEAF_DATA_LIST";
export const SET_ACTIVE_BLEND_DATA = "SET_ACTIVE_BLEND_DATA";
export const ADD_OBSERVATION_DATA = "ADD_OBSERVATION_DATA";
export const SET_ACTIVE_OBSERVATION = "SET_ACTIVE_OBSERVATION";
export const UPDATE_OBSERVATION = "UPDATE_OBSERVATION";
export const UPDATE_PROGRAM_PROGRESS = "UPDATE_PROGRAM_PROGRESS";
export const UPDATE_TEST_NAME = "UPDATE_TEST_NAME";
export const UPDATE_PROGRAM_NAME = "UPDATE_PROGRAM_NAME";
export const REMOVE_JOB_TEST = "REMOVE_JOB_TEST";
export const SET_PRODUCT_RECOMMENDATION = "SET_PRODUCT_RECOMMENDATION";
export const DELETE_BLEND_DATA = "DELETE_BLEND_DATA";
export const SET_ACTIVE_RECOMMENDATION = "SET_ACTIVE_RECOMMENDATION";
export const SET_RECOMMENDATION_LIST = "SET_RECOMMENDATION_LIST";
export const REMOVE_RECOMMENDATION_ITEM = "REMOVE_RECOMMENDATION_ITEM";
export const ADD_BLEND_DATA = "ADD_BLEND_DATA";
export const SET_ACTIVE_RECOMMENDATION_TYPE = "SET_ACTIVE_RECOMMENDATION_TYPE";
export const FETCH_LIST_RECOMMENDATION_REQUEST =
  "FETCH_LIST_RECOMMENDATION_REQUEST";
export const FETCH_LIST_RECOMMENDATION_SUCCESS =
  "FETCH_LIST_RECOMMENDATION_SUCCESS";
export const FETCH_LIST_RECOMMENDATION_FAILURE =
  "FETCH_LIST_RECOMMENDATION_FAILURE";
export const UPDATE_TEST = "UPDATE_TEST";
export const ADD_STANDALONE_BLENDS_TO_SOIL_GROUP =
  "ADD_STANDALONE_BLENDS_TO_SOIL_GROUP";
export const UPDATE_BLEND_DATA = "UPDATE_BLEND_DATA";
export const SET_VIEW_ALL_NOTES = "SET_VIEW_ALL_NOTES";
export const REMOVE_BLEND = "REMOVE_BLEND";
export const SET_ACTIVE = "SET_ACTIVE";
export const RESET_ACTIVE = "RESET_ACTIVE";
export const SET_ACTIVE_SOIL = "SET_ACTIVE_SOIL";
export const RESET_ACTIVE_SOIL = "RESET_ACTIVE_SOIL";
export const ADD_ACTIVITY_LOG = "ADD_ACTIVITY_LOG";
export const SET_SHOW_PROGRAM_NOTES = "SET_SHOW_PROGRAM_NOTES";
export const REMOVE_TESTS = "REMOVE_TESTS";
export const UPDATE_JOB = "UPDATE_JOB";
export const REMOVE_PADDOCK = "REMOVE_PADDOCK";
export const REMOVE_PROPERTY = "REMOVE_PROPERTY";
export const SET_RECOMMENDATIONS_TO_SHORT_POLL =
  "SET_RECOMMENDATIONS_TO_SHORT_POLL";
export const SET_BLEND = "SET_BLEND";

export const resetNavItem = () => ({
  type: RESET_NAV_ITEM,
});

export const setJobsList = (jobs) => ({
  type: SET_JOBS_LIST,
  payload: jobs,
});

export const setFilteredJobsList = (jobs) => ({
  type: SET_FILTERED_JOBS_LIST,
  payload: jobs,
});

export const updateJobList = (job) => ({
  type: UPDATE_JOB_LIST,
  payload: job,
});

export const updateItem = (job) => ({
  type: UPDATE_ITEM,
  payload: job,
});

export const setLoading = (loading) => ({
  type: SET_LOADING,
  payload: loading,
});

export const setCurrentJob = (job, state) => ({
  type: SET_CURRENT_JOB,
  payload: job,
  state,
});

export const setCurrentNavItem = (item) => ({
  type: SET_CURRENT_NAV_ITEM,
  payload: item,
});

export const updateJobStage = (stage, index) => ({
  type: UPDATE_JOB_STAGE,
  stage: stage,
  index: index,
});

export const setNutrients = (data) => ({
  type: SET_NUTRIENTS,
  payload: data,
});

export const setActiveNavTest = (value) => ({
  type: SET_ACTIVE_NAV_TEST,
  payload: value,
});

export const setActiveTest = (test) => ({
  type: SET_ACTIVE_TEST_DATA,
  payload: test,
});

export const addProgram = (program) => ({
  type: ADD_PROGRAM_DATA,
  payload: program,
});

export const setActiveProgram = (index) => ({
  type: SET_ACTIVE_PROGRAM,
  payload: index,
});

export const updateProgram = (
  program,
  maintainActive = false,
  activityLog
) => ({
  type: UPDATE_PROGRAM,
  payload: program,
  maintainActive,
  activityLog,
});

export const updateSoilDateGroup = (data, updateJob) => ({
  type: UPDATE_SOIL_GROUP,
  payload: data,
  updateJob,
});

export const updateLeafDataList = (data, updateJob) => ({
  type: UPDATE_LEAF_DATA_LIST,
  payload: data,
  updateJob,
});

export const setActiveBlendData = (index) => ({
  type: SET_ACTIVE_BLEND_DATA,
  payload: index,
});

export const addObservation = (observation) => ({
  type: ADD_OBSERVATION_DATA,
  payload: observation,
});

export const setActiveObservation = (index) => ({
  type: SET_ACTIVE_OBSERVATION,
  payload: index,
});

export const updateObservation = (observation) => ({
  type: UPDATE_OBSERVATION,
  payload: observation,
});

export const updateProgramProgress = (data) => ({
  type: UPDATE_PROGRAM_PROGRESS,
  payload: data,
});

export const updateTestName = (text, id, type) => ({
  type: UPDATE_TEST_NAME,
  value: text,
  id: id,
  test: type,
});

export const updateProgramName = (value, id) => ({
  type: UPDATE_PROGRAM_NAME,
  value: value,
  id: id,
});

export const deleteTestFromJob = (id, type) => ({
  type: REMOVE_JOB_TEST,
  id: id,
  test: type,
});

export const setProductRecommendation = (data) => ({
  type: SET_PRODUCT_RECOMMENDATION,
  data: data,
});

export const deleteBlendData = (index) => ({
  type: DELETE_BLEND_DATA,
  index: index,
});

export const setRecommendationList = (list) => ({
  type: SET_RECOMMENDATION_LIST,
  payload: list,
});

export const setActiveRecommendationType = (value) => ({
  type: SET_ACTIVE_RECOMMENDATION_TYPE,
  payload: value,
});

export const setActiveRecommendation = (index) => ({
  type: SET_ACTIVE_RECOMMENDATION,
  payload: index,
});

export const removeBlendData = (id, activityLog) => ({
  type: REMOVE_RECOMMENDATION_ITEM,
  payload: id,
  activityLog,
});

export const addBlendData = (value, activityLog) => ({
  type: ADD_BLEND_DATA,
  payload: value,
  activityLog,
});

export const updateBlendData = (value, activityLog) => ({
  type: UPDATE_BLEND_DATA,
  payload: value,
  activityLog,
});

export const updateTest = (payload, activityLog) => ({
  type: UPDATE_TEST,
  payload,
  activityLog,
});

export const addStandaloneSoilBlendsToSoilGroup = (payload, updateJob) => ({
  type: ADD_STANDALONE_BLENDS_TO_SOIL_GROUP,
  payload,
  updateJob,
});

export const setViewAllNotes = (payload) => ({
  type: SET_VIEW_ALL_NOTES,
  payload,
});

export const removeBlend = (payload) => ({
  type: REMOVE_BLEND,
  payload,
});

export const setActive = (payload) => ({ type: SET_ACTIVE, payload });

export const resetActive = () => ({ type: RESET_ACTIVE });

export const setActiveSoil = (payload) => ({
  type: SET_ACTIVE_SOIL,
  payload,
});

export const resetActiveSoil = () => ({
  type: RESET_ACTIVE_SOIL,
});

export const addActivityLog = (payload) => ({
  type: ADD_ACTIVITY_LOG,
  payload,
});

export const setShowProgramNotes = (payload) => ({
  type: SET_SHOW_PROGRAM_NOTES,
  payload,
});

export const removeTests = ({ soilTestIds, leafTestIds, activityLog }) => ({
  type: REMOVE_TESTS,
  soilTestIds,
  leafTestIds,
  activityLog,
});

export const updateJob = (job) => ({ type: UPDATE_JOB, job });

export const removePaddock = (paddockId) => ({
  type: REMOVE_PADDOCK,
  paddockId,
});

export const removeProperty = (propertyId) => ({
  type: REMOVE_PROPERTY,
  propertyId,
});

export const setRecommendationsToShortPoll = (recommendationsToShortPoll) => ({
  type: SET_RECOMMENDATIONS_TO_SHORT_POLL,
  recommendationsToShortPoll,
});

export const setBlend = (blends) => ({ type: SET_BLEND, blends });

// export const fetchRecommendationsList = (id) => {
// 	return async dispatch => {
// 		try {
// 			dispatch({ type: FETCH_LIST_RECOMMENDATION_REQUEST });
// 			// dispatch({ type: FETCH_LIST_RECOMMENDATION_SUCCESS, payload: {} });
// 		} catch (error) {
// 			dispatch({ type: FETCH_LIST_RECOMMENDATION_FAILURE, payload: error.message });
// 		}
// 	}
// 	// return async dispatch => {
// 	// 	try {
// 	// 		console.log('testing dispatch fetchRecommendationsList')
// 	// 	} catch (error) {
// 	// 		console.log(error)
// 	// 	}
// 	// 	// dispatch({ type: FETCH_LIST_RECOMMENDATION_REQUEST });
// 	// 	// try {
// 	// 	//     const url = `${process.env.NEXT_PUBLIC_API_URL}jobs/recommendations?`;
// 	// 	//     const params = { job: uuid };
// 	// 	//     const response = await fetchWrapper.get(url + new URLSearchParams(params));
// 	// 	//     console.log(response)
// 	// 	//     dispatch({ type: FETCH_LIST_RECOMMENDATION_SUCCESS, payload: {} });
// 	// 	// } catch (error) {
// 	// 	//     dispatch({ type: FETCH_LIST_RECOMMENDATION_FAILURE, payload: error.message });
// 	// 	// }
// 	// }
// 	// return async dispatch => ({ type: 'FETCH_LIST_RECOMMENDATION_REQUEST' });
// };

export const getCurrentJob = (uuid) => {};

export const fetchBlendData = (uuid) => {};

// export const fetchCurrentJob = async (uuid) => {
//     const url = `${process.env.NEXT_PUBLIC_API_URL}jobs?`;
//     const params = { job: uuid };
//     const response = await fetchWrapper.get(url + new URLSearchParams(params));

//     if (response.success) {
//         return (dispatch) => {
//             dispatch(setCurrentJob(response.jobs[0]))
//         }
//     } else {

//     }
// };

// export const fetchCurrentJob = (uuid) => async (dispatch, getState) => {
//     console.log(getState())
//     dispatch(setLoading(false))
//     console.log('testing');
// }

// export const fetchCurrentJob = async (uuid) => {
//     console.log('testing')
//     if (!uuid) return;
//     const url = `${process.env.NEXT_PUBLIC_API_URL}jobs?`;
//     const params = { job: uuid };
//     const response = await fetchWrapper.get(url + new URLSearchParams(params));
//     console.log(response)
//     if (response.success) dispatch(setLoading(false));
//     return dispatch(setCurrentJob(response.jobs[0]));
//     // return async dispatch => {
//     //     console.log('it came here!!!')
//     //     dispatch(setLoading(true));

//     //     const url = `${process.env.NEXT_PUBLIC_API_URL}jobs?`;
//     //     const params = { job: uuid };

//     //     const response = await fetchWrapper.get(url + new URLSearchParams(params));
//     //     console.log(response)
//     // };
// };
