/**
 * @readonly
 * @enum {string}
 */
const Filter = {
  ProgramManager: "Program Manager",
  ClientName: "Client name",
};

export default Filter;
