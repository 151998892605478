import Filter from "@/components/jobs-v2/enums/Filter";
import SortBy from "@/components/jobs-v2/enums/SortBy";
import SortOrder from "@/components/jobs-v2/enums/SortOrder";
import {
  SET_JOB_SEARCH,
  SET_JOB_FILTERS,
  SET_JOB_SORT,
  INITIALIZE_JOB_FILTERS,
  RESET_JOB_FILTERS,
} from "../actions/jobsFilterActions";

const initialState = {
  initialized: false,
  search: "",
  filters: Object.values(Filter).reduce((accumulator, filter) => {
    accumulator[filter] = new Set();
    return accumulator;
  }, {}),
  sortBy: SortBy.Date,
  sortOrder: SortOrder.Descending,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case INITIALIZE_JOB_FILTERS: {
      return { ...state, initialized: true };
    }
    case SET_JOB_SEARCH: {
      return { ...state, search: action.payload.search };
    }
    case SET_JOB_FILTERS: {
      const filters = action.payload.filters;

      return {
        ...state,
        filters:
          typeof filters === "function" ? filters(state.filters) : filters,
      };
    }
    case SET_JOB_SORT: {
      return {
        ...state,
        sortBy: action.payload.sortBy,
        sortOrder: action.payload.sortOrder,
      };
    }
    case RESET_JOB_FILTERS: {
      return { ...initialState };
    }
    default: {
      return { ...state };
    }
  }
}
