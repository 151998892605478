import {
  RESET_NAV_ITEM,
  SET_JOBS_LIST,
  SET_LOADING,
  SET_CURRENT_JOB,
  SET_CURRENT_NAV_ITEM,
  UPDATE_JOB_LIST,
  UPDATE_JOB_STAGE,
  SET_NUTRIENTS,
  SET_ACTIVE_NAV_TEST,
  SET_ACTIVE_TEST_DATA,
  ADD_PROGRAM_DATA,
  SET_ACTIVE_PROGRAM,
  UPDATE_PROGRAM,
  UPDATE_SOIL_GROUP,
  UPDATE_LEAF_DATA_LIST,
  SET_ACTIVE_BLEND_DATA,
  SET_ACTIVE_OBSERVATION,
  UPDATE_OBSERVATION,
  ADD_OBSERVATION_DATA,
  UPDATE_PROGRAM_PROGRESS,
  UPDATE_TEST_NAME,
  UPDATE_PROGRAM_NAME,
  REMOVE_JOB_TEST,
  SET_ACTIVE_RECOMMENDATION_TYPE,
  SET_RECOMMENDATION_LIST,
  SET_ACTIVE_RECOMMENDATION,
  REMOVE_RECOMMENDATION_ITEM,
  ADD_BLEND_DATA,
  SET_FILTERED_JOBS_LIST,
  UPDATE_TEST,
  ADD_STANDALONE_BLENDS_TO_SOIL_GROUP,
  UPDATE_BLEND_DATA,
  SET_VIEW_ALL_NOTES,
  REMOVE_BLEND,
  SET_ACTIVE,
  RESET_ACTIVE,
  SET_ACTIVE_SOIL,
  RESET_ACTIVE_SOIL,
  ADD_ACTIVITY_LOG,
  SET_SHOW_PROGRAM_NOTES,
  REMOVE_TESTS,
  UPDATE_JOB,
  REMOVE_PADDOCK,
  REMOVE_PROPERTY,
  SET_RECOMMENDATIONS_TO_SHORT_POLL,
  SET_BLEND,
  // SET_PRODUCT_RECOMMENDATION,
  // DELETE_BLEND_DATA,
  // FETCH_LIST_RECOMMENDATION_REQUEST,
  // FETCH_LIST_RECOMMENDATION_SUCCESS,
  // FETCH_LIST_RECOMMENDATION_FAILURE
} from "@/redux/actions/jobActions";
import update from "immutability-helper";
import moment from "moment";

const initialJobState = {
  navOption: "Results",
  loading: false,
  current: null,
  activeTest: null,
  nutrients: {},
  list: [],
  filteredList: [],
  programs: [],
  observations: [],
  options: [
    { label: "Soil Tests", value: "soil" },
    { label: "Leaf Tests", value: "leaf" },
  ],
  recommendations: {
    list: null,
    type: null,
    active: null,
  },
  error: null,
  viewAllNotes: false,
  activeTestData: false,
  activeTestOption: null,
  activeTestId: null,
  activeTestDateTaken: null,
  soilActiveKey: null,
  showProgramNotes: false,
  recommendationsToShortPoll: new Set(),
};

let testIndex,
  groupIndex = 0;

const jobReducer = (state = initialJobState, action) => {
  switch (action.type) {
    case RESET_NAV_ITEM: {
      return { ...state, navOption: initialJobState.navOption };
    }
    case REMOVE_JOB_TEST:
      if (action.test === "soil") {
        groupIndex = state.current.soil.findIndex((item) =>
          item.data.find((data) => data._id === action.id)
        );
        testIndex = state.current.soil[groupIndex].data.findIndex(
          (item) => item._id === action.id
        );
        return update(state, {
          current: {
            soil: { [groupIndex]: { data: { $splice: [[testIndex, 1]] } } },
          },
        });
      } else {
        testIndex = state.current.leaf.findIndex(
          (item) => item._id === action.id
        );
        return update(state, {
          current: { leaf: { $splice: [[testIndex, 1]] } },
        });
      }
      return { ...state };
    case UPDATE_PROGRAM_NAME:
      const programIndex = state.programs.findIndex(
        (data) => data._id === action.id
      );
      return update(state, {
        programs: { [programIndex]: { name: { $set: action.value } } },
      });
    case UPDATE_TEST_NAME:
      if (action.test === "soil") {
        groupIndex = state.current.soil.findIndex((item) =>
          item.data.find((data) => data._id === action.id)
        );
        testIndex = state.current.soil[groupIndex].data.findIndex(
          (item) => item._id === action.id
        );
        return update(state, {
          current: {
            soil: {
              [groupIndex]: {
                data: { [testIndex]: { name: { $set: action.value } } },
              },
            },
          },
        });
      } else {
        testIndex = state.current.leaf.findIndex(
          (item) => item._id === action.id
        );
        return update(state, {
          current: {
            leaf: { [testIndex]: { cultivation: { $set: action.value } } },
          },
        });
      }
    case SET_JOBS_LIST:
      return { ...state, loading: false, list: action.payload };
    case SET_FILTERED_JOBS_LIST:
      return { ...state, loading: false, filteredList: action.payload };
    case SET_LOADING:
      return { ...state, loading: action.payload };
    case SET_CURRENT_JOB: {
      let newState = {
        ...state,
        ...(action.state ?? {}),
        loading: false,
        current: action.payload,
      };

      if (action.payload?.blendToDelete) {
        newState = {
          ...newState,
          current: {
            ...newState.current,
            blends: newState.current.blends.filter(({ test_id }) =>
              Array.isArray(test_id)
                ? !test_id.includes(action.payload.blendToDelete)
                : test_id !== action.payload.blendToDelete
            ),
          },
        };
      }

      if (newState.activeTestId && newState.activeTestOption) {
        const activeTest = (() => {
          switch (newState.activeTestOption) {
            case "soil":
              return newState.current[newState.activeTestOption].flatMap(
                ({ data }) => data
              );
            case "leaf":
              return newState.current[newState.activeTestOption];
          }
        })().find((test) => test._id === newState.activeTestId);

        if (activeTest) {
          return {
            ...newState,
            activeTest,
            activeTestId: null,
            current: {
              ...newState.current,
              [newState.activeTestOption]: newState.current[
                newState.activeTestOption
              ].map(
                (() => {
                  switch (newState.activeTestOption) {
                    case "soil":
                      return (group) => {
                        return {
                          ...group,
                          data: group.data.map((test) => {
                            if (test._id === activeTest._id) {
                              return { ...test, active: true };
                            }

                            return test;
                          }),
                        };
                      };
                    case "leaf":
                      return (test) => {
                        if (test._id === activeTest._id) {
                          return { ...test, active: true };
                        }

                        return test;
                      };
                  }
                })()
              ),
            },
          };
        }
      }

      return newState;
    }
    // return { ...state, current: action.payload }
    case SET_CURRENT_NAV_ITEM:
      return { ...state, navOption: action.payload };
    case UPDATE_JOB_LIST:
      return {
        ...state,
        list: [...(state.list.length > 0 ? state.list : []), action.payload],
      };
    case UPDATE_JOB_STAGE:
      const newJob = {
        ...state.list[action.index],
        stage: action.stage,
        status: "In Progress",
      };
      return update(state, { list: { [action.index]: { $set: newJob } } });
    case SET_NUTRIENTS:
      return { ...state, nutrients: action.payload };
    case SET_ACTIVE_NAV_TEST:
      const { options } = state;
      const newOptions = options.map((item, index) => {
        item.active = action.payload === index;
        return item;
      });

      return update(state, { options: { $set: newOptions } });
      return state;
    case SET_ACTIVE_TEST_DATA:
      return { ...state, activeTest: action.payload };
    case ADD_PROGRAM_DATA:
      return update(state, { programs: { $push: [action.payload] } });
    case SET_ACTIVE_PROGRAM:
      const newState = state.programs.map((item, index) => {
        item.active = action.payload === index;
        return item;
      });
      return update(state, { programs: { $set: newState } });
    case UPDATE_PROGRAM:
      if (action.maintainActive) {
        const activeProgram = state.programs.find((program) => program.active);

        return {
          ...state,
          programs: action.payload.map((program) => {
            if (program._id === activeProgram?._id) {
              return { ...program, active: true };
            }

            return program;
          }),
        };
      }

      if (typeof action.payload === "function") {
        return {
          ...state,
          programs: action.payload(state.programs),
          current: {
            ...state.current,
            ...(action.activityLog?.value
              ? {
                  activityLogs: [
                    ...(state.current.activityLogs
                      ? state.current.activityLogs
                      : []),
                    action.activityLog.value,
                  ],
                }
              : {}),
          },
        };
      }

      return {
        ...update(state, { programs: { $set: action.payload } }),
        current: {
          ...state.current,
          ...(action.activityLog
            ? {
                activityLogs: [
                  ...(state.current.activityLogs
                    ? state.current.activityLogs
                    : []),
                  action.activityLog,
                ],
              }
            : {}),
        },
      };
    case UPDATE_SOIL_GROUP: {
      const group = action.payload._id;

      groupIndex = state.current.soil.findIndex(
        (item) => item._id.month == group.month && item._id.year == group.year
      );

      const newState =
        groupIndex !== -1
          ? update(state, {
              current: {
                soil: {
                  [groupIndex]: { data: { $push: [action.payload.data[0]] } },
                },
              },
            })
          : update(state, { current: { soil: { $push: [action.payload] } } });

      if (action.updateJob) {
        return {
          ...newState,
          current: {
            ...newState.current,
            ...action.updateJob(newState.current),
          },
        };
      }

      return newState;
    }
    case ADD_STANDALONE_BLENDS_TO_SOIL_GROUP: {
      const { standaloneBlends, setActiveData } = action.payload;
      const soil = JSON.parse(JSON.stringify([...(state.current?.soil ?? [])]));

      for (const standaloneBlend of standaloneBlends) {
        const dateAdded = new Date(standaloneBlend.dateAdded);
        const month = dateAdded.getMonth() + 1;
        const year = dateAdded.getFullYear();

        const data = soil.find(({ _id }) => {
          return _id.month === month && _id.year === year;
        })?.data;

        if (data) {
          data.push(standaloneBlend);
        } else {
          soil.push({
            _id: { month, year },
            key: moment(standaloneBlend.dateAdded).format("YYYY-MM"),
            data: [standaloneBlend],
          });
        }
      }

      soil.sort((a, b) => {
        a = new Date(a.key);
        b = new Date(b.key);
        return b.getFullYear() - a.getFullYear() || b.getMonth() - a.getMonth();
      });

      const newState = { ...state, current: { ...state.current, soil } };

      if (setActiveData) {
        const activeData = newState.current.soil.find(
          ({ key }) => key === newState.soilActiveKey
        );

        if (activeData) {
          newState.recommendations.active = newState.current.soil.findIndex(
            ({ key }) => key === newState.soilActiveKey
          );

          setActiveData(activeData);

          newState.navOption = "Recommendations";
          newState.soilActiveKey = null;
        }
      }

      if (action.updateJob) {
        return {
          ...newState,
          current: {
            ...newState.current,
            ...action.updateJob(newState.current),
          },
        };
      }

      return newState;
    }
    case UPDATE_LEAF_DATA_LIST: {
      const newState = update(state, {
        current: { leaf: { $push: [action.payload] } },
      });

      if (action.updateJob) {
        return {
          ...newState,
          current: {
            ...newState.current,
            ...action.updateJob(newState.current),
          },
        };
      }

      return newState;
    }
    case SET_ACTIVE_BLEND_DATA:
      const newFiles = state.current.blends.map((item, index) => {
        item.active = action.payload === index;
        return item;
      });
      return update(state, { current: { files: { $set: newFiles } } });
    case ADD_OBSERVATION_DATA:
      return update(state, { observations: { $push: [action.payload] } });
    case SET_ACTIVE_OBSERVATION:
      const newStateObservation = state.observations.map((item, index) => {
        item.active = action.payload === index;
        return item;
      });
      return update(state, { observations: { $set: newStateObservation } });
    case UPDATE_OBSERVATION:
      return update(state, { observations: { $set: action.payload } });
    case UPDATE_PROGRAM_PROGRESS:
      return update(state, {
        programs: {
          [state.programs.findIndex((p) => p.active)]: {
            products: {
              [action.payload.groupIndex]: {
                data: {
                  [action.payload.productIndex]: {
                    application: {
                      [action.payload.applicationIndex]: {
                        checked: { $set: action.payload.value },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });
    case SET_RECOMMENDATION_LIST:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          list: action.payload,
        },
      };
    case SET_ACTIVE_RECOMMENDATION_TYPE:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          type: action.payload,
        },
      };

    case SET_ACTIVE_RECOMMENDATION:
      return {
        ...state,
        recommendations: {
          ...state.recommendations,
          active: action.payload,
        },
      };
    case REMOVE_RECOMMENDATION_ITEM:
      return {
        ...state,
        current: {
          ...state.current,
          blends: state.current.blends.filter((i) => i._id != action.payload),
          ...(action.activityLog
            ? {
                activityLogs: [
                  ...(state.current.activityLogs
                    ? state.current.activityLogs
                    : []),
                  action.activityLog,
                ],
              }
            : {}),
        },
        recommendations: {
          ...state.recommendations,
          list: state.recommendations.list.filter(
            (i) => i._id != action.payload
          ),
        },
      };
    case ADD_BLEND_DATA:
      return {
        ...state,
        current: {
          ...state.current,
          blends: [...state.current.blends, action.payload],
          ...(action.activityLog
            ? {
                activityLogs: [
                  ...(state.current.activityLogs
                    ? state.current.activityLogs
                    : []),
                  action.activityLog,
                ],
              }
            : {}),
        },
      };
    case UPDATE_BLEND_DATA: {
      return {
        ...state,
        current: {
          ...state.current,
          blends: state.current.blends.map((blend) => {
            if (blend._id === action.payload._id) {
              const { _id, ...values } = action.payload;
              return { ...blend, ...values };
            }

            return blend;
          }),
          ...(action.activityLog
            ? {
                activityLogs: [
                  ...(state.current.activityLogs
                    ? state.current.activityLogs
                    : []),
                  action.activityLog,
                ],
              }
            : {}),
        },
      };
    }
    case UPDATE_TEST: {
      const testType = action.payload.testType;

      const tests = (() => {
        switch (testType) {
          case "leaf":
            return state.current[testType].map((test) => {
              if (test._id === action.payload._id) {
                return { ...test, ...action.payload };
              }

              return test;
            });
          case "soil":
            return state.current[testType].map(({ ...group }) => {
              group.data = group.data.map((datum) => {
                if (datum._id === action.payload._id) {
                  const { nutrients, ...payload } = action.payload;

                  return {
                    ...datum,
                    ...payload,
                    ...(nutrients
                      ? { nutrients: { ...datum.nutrients, ...nutrients } }
                      : {}),
                  };
                }

                return datum;
              });

              return group;
            });
        }
      })();

      const { nutrients, ...payload } = action.payload;

      const newState = {
        ...state,
        ...(state.activeTest?._id === payload._id
          ? {
              activeTest: {
                ...state.activeTest,
                ...payload,
                ...(nutrients
                  ? {
                      nutrients: {
                        ...state.activeTest.nutrients,
                        ...nutrients,
                      },
                    }
                  : {}),
              },
            }
          : {}),
        current: {
          ...state.current,
          [testType]: tests,
        },
      };

      if (action.activityLog) {
        return {
          ...newState,
          current: {
            ...newState.current,
            activityLogs: [
              ...(newState.current.activityLogs
                ? newState.current.activityLogs
                : []),
              action.activityLog,
            ],
          },
        };
      }

      return newState;
    }
    case SET_VIEW_ALL_NOTES:
      return {
        ...state,
        viewAllNotes: action.payload,
      };
    case REMOVE_BLEND: {
      return {
        ...state,
        current: {
          ...state.current,
          blends:
            state.current.blends.filter(
              (blend) => blend._id !== action.payload._id
            ) ?? [],
        },
      };
    }
    case SET_ACTIVE: {
      return {
        ...state,
        activeTestData: action.payload.activeTestData ?? state.activeTestData,
        activeTestOption:
          action.payload.activeTestOption ?? state.activeTestOption,
        activeTestId: action.payload.activeTestId,
        activeTestDateTaken: action.payload.activeTestDateTaken,
      };
    }
    case RESET_ACTIVE: {
      return {
        ...state,
        ...initialJobState,
      };
    }
    case SET_ACTIVE_SOIL: {
      return {
        ...state,
        soilActiveKey: action.payload.soilActiveKey ?? state.soilActiveKey,
        recommendations: {
          ...state.recommendations,
          type: "soil",
        },
      };
    }
    case RESET_ACTIVE_SOIL: {
      return {
        ...state,
        soilActiveKey: initialJobState.soilActiveKey,
      };
    }
    case ADD_ACTIVITY_LOG: {
      return {
        ...state,
        current: {
          ...state.current,
          activityLogs: [...(state.current.activityLogs ?? []), action.payload],
        },
      };
    }
    case SET_SHOW_PROGRAM_NOTES: {
      return { ...state, showProgramNotes: action.payload };
    }
    case REMOVE_TESTS: {
      return {
        ...state,
        current: {
          ...state.current,
          ...(action.soilTestIds?.size
            ? {
                soil: state.current.soil.map((soil) => ({
                  ...soil,
                  data: soil.data.filter(({ _id }) => {
                    return !action.soilTestIds.has(_id);
                  }),
                })),
              }
            : {}),
          ...(action.leafTestIds?.size
            ? {
                leaf: state.current.leaf.filter(({ _id }) => {
                  return !action.leafTestIds.has(_id);
                }),
              }
            : {}),
          ...(action.activityLog
            ? {
                activityLogs: [
                  ...(state.current.activityLogs ?? []),
                  action.activityLog,
                ],
              }
            : {}),
        },
      };
    }
    case UPDATE_JOB: {
      return { ...state, current: { ...state.current, ...action.job } };
    }
    case REMOVE_PADDOCK: {
      return {
        ...state,
        current: {
          ...state.current,
          soil: state.current.soil.map((soil) => ({
            ...soil,
            data: soil.data.map((test) => {
              if (test.paddockId === action.paddockId) {
                return { ...test, paddockId: null };
              }

              return test;
            }),
          })),
          leaf: state.current.leaf.map((test) => {
            if (test.paddockId === action.paddockId) {
              return { ...test, paddockId: null };
            }

            return test;
          }),
        },
      };
    }
    case REMOVE_PROPERTY: {
      return {
        ...state,
        current: {
          ...state.current,
          soil: state.current.soil.map((soil) => ({
            ...soil,
            data: soil.data.map((test) => {
              if (test.propertyId === action.propertyId) {
                return { ...test, paddockId: null, propertyId: null };
              }

              return test;
            }),
          })),
          leaf: state.current.leaf.map((test) => {
            if (test.propertyId === action.propertyId) {
              return { ...test, paddockId: null, propertyId: null };
            }

            return test;
          }),
        },
      };
    }
    case SET_RECOMMENDATIONS_TO_SHORT_POLL: {
      return {
        ...state,
        recommendationsToShortPoll:
          typeof action.recommendationsToShortPoll === "function"
            ? action.recommendationsToShortPoll(
                state.recommendationsToShortPoll
              )
            : action.recommendationsToShortPoll,
      };
    }
    case SET_BLEND: {
      return {
        ...state,
        current: {
          ...state.current,
          blends:
            typeof action.blends === "function"
              ? action.blends(state.current.blends)
              : action.blends,
        },
      };
    }
    // case SET_ACTIVE_RECOMMENDATION_TYPE:
    // 	console.log(action.payload)
    // 	return state;
    // // case SET_PRODUCT_RECOMMENDATION:
    // //     return { ...state, recommendations: action.payload }
    // // case FETCH_LIST_RECOMMENDATION_REQUEST:
    // // 	return {
    // // 		...state,
    // // 		loading: true,
    // // 		error: null
    // // 	};
    // // case FETCH_LIST_RECOMMENDATION_SUCCESS:
    // // 	return {
    // // 		...state,
    // // 		loading: false,
    // // 		recommendations: action.payload
    // // 	};
    // // case FETCH_LIST_RECOMMENDATION_FAILURE:
    // // 	return {
    // // 		...state,
    // // 		loading: false,
    // // 		error: action.payload
    // // 	};
    default:
      return { ...state };
  }
};

export default jobReducer;
