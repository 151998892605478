import {
  CLEAR_PROGRAM_TEMPLATE,
  SET_PROGRAM_TEMPLATE,
} from "../actions/programTemplateActions";

const initialState = {
  programTemplate: null,
  breadcrumb: null,
  jobId: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_PROGRAM_TEMPLATE: {
      return {
        ...state,
        programTemplate: action.payload.programTemplate,
        breadcrumb: action.payload.breadcrumb,
        jobId: action.payload.jobId,
      };
    }
    case CLEAR_PROGRAM_TEMPLATE: {
      return { ...state, programTemplate: null, breadcrumb: null };
    }
    default: {
      return state;
    }
  }
}
