import { RESET_CLIENT_NAV_OPTION, SET_ADD_UPDATE_CLIENT, SET_CLIENT, SET_CLIENT_CURRENT_NAV_ITEM, SET_CLIENT_JOBS_LIST, SET_CLIENT_LIST, SET_CLIENT_PROPERTY, SET_CLIENT_PROPERTY_LIST, SET_FILTERED_JOBS_LIST } from '@/redux/actions/clientActions'

const initialUserState = {
    navOption: 'Results',
    data: {},
    addUpdate: {},
    list: [],
    jobList: [],
    filteredJobList: [],
    propertyList: [],
    property: {}
};

const clientReducer = (state = initialUserState, action) => {
    switch (action.type) {
        case RESET_CLIENT_NAV_OPTION: {
            return { ...state, navOption: initialUserState.navOption };
        }
        case SET_CLIENT:
            return { ...state, data: action.payload }
        case SET_CLIENT_LIST:
            return { ...state, list: action.payload }
        case SET_ADD_UPDATE_CLIENT:
            return { ...state, addUpdate: action.payload }
        case SET_CLIENT_JOBS_LIST:
            return { ...state, jobList: typeof action.payload === 'function' ? action.payload(state.jobList) : action.payload }
        case SET_FILTERED_JOBS_LIST:
            return { ...state, filteredJobList: action.payload }
        case SET_CLIENT_CURRENT_NAV_ITEM:
            return { ...state, navOption: action.payload }
        case SET_CLIENT_PROPERTY_LIST:
            return { ...state, propertyList: action.payload }
        case SET_CLIENT_PROPERTY:
            return { ...state, property: action.payload }
        default:
            return { ...state }
    }
};

export default clientReducer;
