export const SET_PROGRAM_TEMPLATE = "SET_PROGRAM_TEMPLATE";
export const CLEAR_PROGRAM_TEMPLATE = "CLEAR_PROGRAM_TEMPLATE";

export function setProgramTemplate(payload) {
  return { type: SET_PROGRAM_TEMPLATE, payload };
}

export function clearProgramTemplate() {
  return { type: CLEAR_PROGRAM_TEMPLATE };
}
