import { BehaviorSubject } from 'rxjs';
import getConfig from 'next/config';
import Router from 'next/router';
import { fetchWrapper } from '@/lib/fetch-wrapper';

const { publicRuntimeConfig } = getConfig();
const baseUrl = `${publicRuntimeConfig.apiUrl}/users`;
const userSubject = new BehaviorSubject(process.browser && JSON.parse(localStorage.getItem('hguser')));

export const userService = {
    user: userSubject.asObservable(),
    get userValue() { return userSubject.value },
    login,
    logout,
    update
}

async function login(username, password) {
    const apiURL = '/api/authenticate';
    const response = await fetchWrapper.post(apiURL, { username, password });

    if (response.error) {
        if (response.error == 'NO_PASS') {
            Router.push(`/forgot-password?action=reset&id=${response.user}`);
        } else {
            return response;
        }
    }

    if (response.success) {
        localStorage.setItem('hguser', JSON.stringify(response.user));
        userSubject.next(response.user);

        return response;
    }
}

function logout() {
    localStorage.removeItem('hguser');
    userSubject.next(null);
    Router.push('/login');
}

function update(data) {
    const newData = { ...userSubject.value, ...data };
    localStorage.setItem('hguser', JSON.stringify(newData));

    return newData;
}