import { useRef, useState, useEffect } from 'react';
import { userService } from '@/services/user-service';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import { setUser } from '@/redux/actions/userActions';

const RouteGuard = ({ children }) => {
    const mounted = useRef(false);
    const router = useRouter();
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.data);
    const [authorized, setAuthorized] = useState(false);
    const userData = userService.userValue;

    useEffect(() => {
        userData && userData.token && dispatch(setUser(userData));
    }, [userData]);

    useEffect(() => {
        mounted.current = true;
        const authCheck = (url) => {
            // before returning children, check if user is logged in, look for jwt local storage
            const publicPaths = process.env.NEXT_PUBLIC_PATHS.split(',');
            const path = url.split('?')[0];

            // if path belongs to public access, just give access directly
            if (publicPaths.includes(path)) {
                setAuthorized(true);
            } else {
                if (!userData && !user) {
                    router.replace('/login?' + new URLSearchParams({ returnUrl: router.asPath }));
                } else {
                    setAuthorized(true);
                }
            }
        }

        const hideContent = () => setAuthorized(false);
        router.events.on('routeChangeStart', hideContent);
        router.events.on('routeChangeComplete', authCheck);

        mounted.current && authCheck(router.asPath);

        return () => {
            router.events.off('routeChangeStart', hideContent);
            router.events.off('routeChangeComplete', authCheck);
            mounted.current = false;
        }
    }, [user, router.asPath]);

    return authorized && children;
}

export default RouteGuard;